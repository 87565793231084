<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'

import DocsService from '@/services/content-app/docs.service'
import DocCategoriesService, { scrollToTop } from '@/services/content-app/docCategories.service'
import ContentService from '@/utils/content-app/content.utils'
import type { IDoc } from '@/components/content-app/interface/docs/IDoc'
import { useRoute, useRouter } from 'vue-router'
import { useSSGStore } from '@/stores/ssg.store'

const route = useRoute()
const router = useRouter()
const _contentService = new ContentService()
const _docCategoriesService = new DocCategoriesService()
const _docsService = new DocsService()
const doc = ref<IDoc>({} as IDoc)
const nextDoc = ref<IDoc | null>(null)
const prevDoc = ref<IDoc | null>(null)
const docs = ref<IDoc[]>([])

/**
 * @returns — number
 * @param array — is docs
 * @param currentItem — current doc
 */
const findIndex = (array: IDoc[], currentItem: IDoc): number => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === currentItem.id) {
      return i
    }
  }
  return -1
}

/**
 * @returns — null or doc
 * @param array — is docs
 * @param currentItem — current doc
 */
const GetNextDoc = (array: IDoc[], currentItem: IDoc): IDoc | null => {
  const currentIndex = findIndex(array, currentItem)
  if (currentIndex !== -1 && currentIndex < array.length - 1) {
    return array[currentIndex + 1]
  }
  return null
}

/**
 * Retrieves the previous document in the array relative to the current document.
 * @param array — Array of documents.
 * @param currentItem — Current document in context.
 * @returns The previous document if available; returns null if there is no previous document.
 */
const getPreviousDoc = (array: IDoc[], currentItem: IDoc): IDoc | null => {
  const currentIndex = findIndex(array, currentItem)

  if (currentIndex > 0) {
    return array[currentIndex - 1]
  }
  return null
}

/**
 * Navigates to the previous document's page if available.
 */
const previous = async () => {
  prevDoc.value = getPreviousDoc(docs.value, doc.value)

  if (prevDoc.value) {
    await router.push({
      path: `/docs/${route.params.category}/${prevDoc.value.attributes.Url}`
    })
  }
  scrollToTop()
}

/**
 * next doc
 */
const next = async () => {
  nextDoc.value = GetNextDoc(docs.value, doc.value)

  if (nextDoc.value) {
    router.push({
      path: `/docs/${route.params.category}/${nextDoc.value.attributes.Url}`
    })
  }
  scrollToTop()
}

if (import.meta.env.SSR) {
  const data = useSSGStore().storeDocumentData
  const routeParams = {
    category: route.params.category,
    doc: route.params.doc
  }

  const categoryMatch = data.find((item) => item.attributes.Url === routeParams.category)

  if (categoryMatch) {
    const docMatch = categoryMatch.attributes.docs.data.find(
      (item) => item.attributes.Url === routeParams.doc
    )
    if (docMatch) {
      doc.value = docMatch
    }
  }
}

onBeforeMount(async () => {
  const data = await _docsService.GetDocByUrl(route.params.doc as string)
  const docCategory = await _docCategoriesService.GetOneDocCategory(route.params.category as string)

  if (docCategory) {
    docs.value = docCategory.attributes.docs.data
  }

  if (data) {
    doc.value = data

    const htmlWithConvertedIframes = _contentService.convertOembedToIframe(
      doc.value.attributes.Content
    )
    const modifiedHtml =
      _contentService.injectIdIntoHtml_returnHtmlAndLinks(htmlWithConvertedIframes)
    doc.value.attributes.Content = modifiedHtml.modifiedHtml
  } else {
    router.push('/docs')
  }

  nextDoc.value = GetNextDoc(docs.value, doc.value)
  prevDoc.value = getPreviousDoc(docs.value, doc.value)
})
</script>
<template>
  <div>
    <div v-if="doc.attributes" class="py-3 text-text-gold sm:py-6 lg:pb-10 lg:pt-4">
      <h1 class="text-xl font-bold sm:text-2xl lg:text-4xl">
        {{ doc.attributes.Title }}
      </h1>
      <p class="mt-3 text-[10px] sm:text-sm lg:text-base">{{ doc.attributes.Description }}</p>
      <div class="doc_content mt-3 break-words sm:mt-6" v-html="doc.attributes.Content" />
    </div>
    <div
      v-if="prevDoc || nextDoc"
      class="flex items-center justify-between border-t border-[#D9D9D9]"
    >
      <div v-if="prevDoc !== null" class="mb-7 py-7 sm:mb-9 sm:py-9">
        <h5 class="color-secondary-600 text-xl font-semibold sm:text-[28px]">Previous Topic</h5>
        <button class="color-caviar hover:color-secondary mt-3" @click="previous">
          <FontAwesomeIcon icon="fa-arrow-left" />
          <span class="ml-2.5 text-base font-semibold sm:text-lg">
            {{ prevDoc.attributes.Title }}
          </span>
        </button>
      </div>
      <div v-if="nextDoc !== null" class="mb-7 py-7 sm:mb-9 sm:py-9">
        <h5 class="color-secondary-600 text-xl font-semibold sm:text-[28px]">Next Topic</h5>
        <button class="color-caviar hover:color-secondary mt-3" @click="next">
          <span class="mr-2.5 text-base font-semibold sm:text-lg">
            {{ nextDoc.attributes.Title }}
          </span>
          <FontAwesomeIcon icon="fa-arrow-right" />
        </button>
      </div>
    </div>
  </div>
</template>
